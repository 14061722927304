import React from "react";
import { gsap, ScrollTrigger} from "gsap/all";

function ScrollTest(props) {
  // First star
  React.useEffect(() => {
    if (props) {
      gsap.registerPlugin(ScrollTrigger);
      // gsap.registerPlugin(MorphSVGPlugin);

      var svg1TL = gsap.timeline({
        scrollTrigger: {
          trigger: "#clip1 .clip__inner",
          scrub: 2,
          pin: true,
          pinSpacing: false,
          invalidateOnRefresh: true
        }
      })

      svg1TL.fromTo("#svg1 video, #svg1 img", { y: "30%" }, { y: 0 }, 0)
      svg1TL.to("#svg__star", { morphSVG: { shape: "#svg__rec", origin: "50% 50%" }, ease: "none" }, 0);
      svg1TL.fromTo("#svg1", { width: () => { if (window.innerWidth < 600) { return 70 } else { return 200 } } }, { width: () => { if (window.innerWidth < window.innerHeight) { return "100vh" } else { return "100vw" } }, ease: "none" }, 0);

      // Stars 2,3,4 could be in a function
      // Second star
      gsap.set("#svg2", { y: "100vh" })
      gsap.to("#svg2", {
        y: 0, x: 0, scrollTrigger: {
          trigger: "body",
          scrub: true,
          start: () => window.innerHeight * 2 + " bottom",
          end: () => window.innerHeight * 3 + " bottom"
        }, ease: "none"
      })

      var svg2TL = gsap.timeline({
        scrollTrigger: {
          trigger: "body",
          scrub: 2,
          start: () => window.innerHeight * 3 + " bottom",
          end: () => window.innerHeight * 4 + " bottom",
          invalidateOnRefresh: true
        }
      })

      svg2TL.fromTo("#svg2 video, #svg2 img", { y: "30%" }, { y: 0 }, 0)
      svg2TL.to("#svg__star2", { morphSVG: { shape: "#svg__rec2", origin: "50% 50%" } }, 0);
      svg2TL.fromTo("#svg2", { width: () => { if (window.innerWidth < 600) { return 70 } else { return 200 } } }, { width: () => { if (window.innerWidth < window.innerHeight) { return "100vh" } else { return "100vw" } }, ease: "none" }, 0);

      // Third star
      gsap.set("#svg3", { y: "100vh" })
      gsap.to("#svg3", {
        y: 0, x: 0, scrollTrigger: {
          trigger: "body",
          scrub: true,
          start: () => window.innerHeight * 4 + " bottom",
          end: () => window.innerHeight * 5 + " bottom",
          invalidateOnRefresh: true
        }, ease: "none"
      })

      var svg3TL = gsap.timeline({
        scrollTrigger: {
          trigger: "body",
          scrub: 2,
          start: () => window.innerHeight * 5 + " bottom",
          end: () => window.innerHeight * 6 + " bottom",
          invalidateOnRefresh: true
        }
      })

      svg3TL.fromTo("#svg3 video, #svg3 img", { y: "30%" }, { y: 0 }, 0)
      svg3TL.to("#svg__star3", { morphSVG: { shape: "#svg__rec3", origin: "50% 50%" } }, 0);
      svg3TL.fromTo("#svg3", { width: () => { if (window.innerWidth < 600) { return 70 } else { return 200 } } }, { width: () => { if (window.innerWidth < window.innerHeight) { return "100vh" } else { return "100vw" } }, ease: "none" }, 0);

      // Fourth star
      gsap.set("#svg4", { y: () => window.innerHeight })
      gsap.to("#svg4", {
        y: 0, x: 0, scrollTrigger: {
          trigger: "body",
          scrub: true,
          start: () => window.innerHeight * 6 + " bottom",
          end: () => window.innerHeight * 7 + " bottom"
        }, ease: "none"
      })

      var svg4TL = gsap.timeline({
        scrollTrigger: {
          trigger: "body",
          scrub: 2,
          start: () => window.innerHeight * 7 + " bottom",
          end: () => window.innerHeight * 8 + " bottom"
        }
      })

      svg4TL.fromTo("#svg4 video, #svg4 img", { y: "30%" }, { y: 0 }, 0)
      svg4TL.to("#svg__star4", { morphSVG: { shape: "#svg__rec4", origin: "50% 50%" } }, 0);
      svg4TL.fromTo("#svg4", { width: () => { if (window.innerWidth < 600) { return 70 } else { return 200 } } }, { width: () => { if (window.innerWidth < window.innerHeight) { return "100vh" } else { return "100vw" } }, ease: "none" }, 0);
    }
  }, [props]);

  return (
    <>
      <div className="flex__col">
        <section className="clip" id="clip1">
          <div className="clip__inner flex__col">
            <h1 className="flex">Find your star</h1>
            <div className="clip__cols flex">
              <p>
                SOTD Challenge : Insprired by <a href="https://2authenticators.co/">2 Authenticators</a> website animation (SOTD, Apr 11, 2023)
                <br /><br />
                First time exploring Morph SVG.
              </p>
              <p>
                Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
                <br />
                Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
              </p>
            </div>
          </div>
        </section>
        <section className="clip__scroll flex__col" id="clip1__scroll">
          <h2>Le glacier</h2>
          <div className="clip__cols flex">
            <p>
              SOTD Challenge : Insprired by <a href="https://2authenticators.co/">2 Authenticators</a> website animation (SOTD, Apr 11, 2023)
              <br /><br />
              First time exploring Morph SVG.
            </p>
            <p>
              Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
              <br />
              Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
            </p>
          </div>
        </section>
        <section className="clip__scroll flex__col" id="clip2__scroll">
          <h2>Le lac</h2>
          <div className="clip__cols flex">
            <p>
              SOTD Challenge : Insprired by <a href="https://2authenticators.co/">2 Authenticators</a> website animation (SOTD, Apr 11, 2023)
              <br /><br />
              First time exploring Morph SVG.
            </p>
            <p>
              Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
              <br />
              Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
            </p>
          </div>
        </section>
        <section className="clip__scroll flex__col" id="clip3__scroll">
          <h2>La montagne</h2>
          <div className="clip__cols flex">
            <p>
              SOTD Challenge : Insprired by <a href="https://2authenticators.co/">2 Authenticators</a> website animation (SOTD, Apr 11, 2023)
              <br /><br />
              First time exploring Morph SVG.
            </p>
            <p>
              Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
              <br />
              Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
            </p>
          </div>
        </section>
        <section className="clip__scroll flex__col" id="clip4__scroll">
          <h2>L'océan</h2>
          <div className="clip__cols flex">
            <p>
              SOTD Challenge : Insprired by <a href="https://2authenticators.co/">2 Authenticators</a> website animation (SOTD, Apr 11, 2023)
              <br /><br />
              First time exploring Morph SVG.
            </p>
            <p>
              Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
              <br />
              Nulla hendrerit metus lacinia magna rhoncus suscipit. Sed faucibus posuere tempor. Ut suscipit et urna a gravida.
            </p>
          </div>
        </section>
        <figure className="starSvg" id="svg1">
          <svg height="0" width="0">
            <defs>
              <clipPath id="svg__path" clipPathUnits="objectBoundingBox">
                <path id="svg__rec" data-name="rec" d="M0,0H1V1H0Z" />
                <path id="svg__star" data-name="star" d="M 0.5 0.0390625 C 0.472656 0.214844 0.449219 0.320312 0.382812 0.382812 C 0.320312 0.449219 0.214844 0.472656 0.0390625 0.5 C 0.214844 0.527344 0.320312 0.550781 0.382812 0.617188 C 0.449219 0.679688 0.472656 0.785156 0.5 0.960938 C 0.527344 0.785156 0.550781 0.679688 0.617188 0.617188 C 0.679688 0.550781 0.785156 0.527344 0.960938 0.5 C 0.785156 0.472656 0.679688 0.449219 0.617188 0.382812 C 0.550781 0.320312 0.527344 0.214844 0.5 0.0390625 Z M 0.828125 0.171875 C 0.738281 0.238281 0.667969 0.285156 0.605469 0.3125 C 0.617188 0.328125 0.628906 0.34375 0.640625 0.359375 C 0.65625 0.371094 0.671875 0.382812 0.6875 0.394531 C 0.714844 0.332031 0.761719 0.261719 0.828125 0.171875 Z M 0.175781 0.175781 C 0.238281 0.261719 0.285156 0.332031 0.3125 0.394531 C 0.328125 0.382812 0.34375 0.371094 0.359375 0.359375 C 0.371094 0.34375 0.382812 0.328125 0.394531 0.3125 C 0.332031 0.285156 0.261719 0.238281 0.175781 0.175781 Z M 0.6875 0.605469 C 0.671875 0.617188 0.65625 0.628906 0.640625 0.640625 C 0.628906 0.65625 0.617188 0.671875 0.605469 0.6875 C 0.667969 0.714844 0.738281 0.761719 0.828125 0.828125 C 0.761719 0.738281 0.714844 0.667969 0.6875 0.605469 Z M 0.3125 0.605469 C 0.285156 0.667969 0.238281 0.738281 0.171875 0.828125 C 0.261719 0.761719 0.332031 0.714844 0.394531 0.6875 C 0.382812 0.671875 0.371094 0.65625 0.359375 0.640625 C 0.34375 0.628906 0.328125 0.617188 0.3125 0.605469 Z M 0.3125 0.605469 " />
              </clipPath>
            </defs>
          </svg>
          <video loop autoPlay muted poster="https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            src="http://thenewcode.com/assets/videos/glacier.mp4" type="video/mp4">
          </video>
        </figure>
        <figure className="starSvg" id="svg2">
          <svg height="0" width="0">
            <defs>
              <clipPath id="svg__path2" clipPathUnits="objectBoundingBox">
                <path id="svg__rec2" data-name="rec2" d="M0,0H1V1H0Z" />
                <path id="svg__star2" data-name="star2" d="M 0.5 0.0390625 C 0.472656 0.214844 0.449219 0.320312 0.382812 0.382812 C 0.320312 0.449219 0.214844 0.472656 0.0390625 0.5 C 0.214844 0.527344 0.320312 0.550781 0.382812 0.617188 C 0.449219 0.679688 0.472656 0.785156 0.5 0.960938 C 0.527344 0.785156 0.550781 0.679688 0.617188 0.617188 C 0.679688 0.550781 0.785156 0.527344 0.960938 0.5 C 0.785156 0.472656 0.679688 0.449219 0.617188 0.382812 C 0.550781 0.320312 0.527344 0.214844 0.5 0.0390625 Z M 0.828125 0.171875 C 0.738281 0.238281 0.667969 0.285156 0.605469 0.3125 C 0.617188 0.328125 0.628906 0.34375 0.640625 0.359375 C 0.65625 0.371094 0.671875 0.382812 0.6875 0.394531 C 0.714844 0.332031 0.761719 0.261719 0.828125 0.171875 Z M 0.175781 0.175781 C 0.238281 0.261719 0.285156 0.332031 0.3125 0.394531 C 0.328125 0.382812 0.34375 0.371094 0.359375 0.359375 C 0.371094 0.34375 0.382812 0.328125 0.394531 0.3125 C 0.332031 0.285156 0.261719 0.238281 0.175781 0.175781 Z M 0.6875 0.605469 C 0.671875 0.617188 0.65625 0.628906 0.640625 0.640625 C 0.628906 0.65625 0.617188 0.671875 0.605469 0.6875 C 0.667969 0.714844 0.738281 0.761719 0.828125 0.828125 C 0.761719 0.738281 0.714844 0.667969 0.6875 0.605469 Z M 0.3125 0.605469 C 0.285156 0.667969 0.238281 0.738281 0.171875 0.828125 C 0.261719 0.761719 0.332031 0.714844 0.394531 0.6875 C 0.382812 0.671875 0.371094 0.65625 0.359375 0.640625 C 0.34375 0.628906 0.328125 0.617188 0.3125 0.605469 Z M 0.3125 0.605469 " />
              </clipPath>
            </defs>
          </svg>
          <video loop autoPlay muted poster="https://images.unsplash.com/photo-1494253188410-ff0cdea5499e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            src="http://thenewcode.com/assets/videos/lake.mp4" type="video/mp4">
          </video>
        </figure>
        <figure className="starSvg" id="svg3">
          <svg height="0" width="0">
            <defs>
              <clipPath id="svg__path3" clipPathUnits="objectBoundingBox">
                <path id="svg__rec3" data-name="rec3" d="M0,0H1V1H0Z" />
                <path id="svg__star3" data-name="star3" d="M 0.5 0.0390625 C 0.472656 0.214844 0.449219 0.320312 0.382812 0.382812 C 0.320312 0.449219 0.214844 0.472656 0.0390625 0.5 C 0.214844 0.527344 0.320312 0.550781 0.382812 0.617188 C 0.449219 0.679688 0.472656 0.785156 0.5 0.960938 C 0.527344 0.785156 0.550781 0.679688 0.617188 0.617188 C 0.679688 0.550781 0.785156 0.527344 0.960938 0.5 C 0.785156 0.472656 0.679688 0.449219 0.617188 0.382812 C 0.550781 0.320312 0.527344 0.214844 0.5 0.0390625 Z M 0.828125 0.171875 C 0.738281 0.238281 0.667969 0.285156 0.605469 0.3125 C 0.617188 0.328125 0.628906 0.34375 0.640625 0.359375 C 0.65625 0.371094 0.671875 0.382812 0.6875 0.394531 C 0.714844 0.332031 0.761719 0.261719 0.828125 0.171875 Z M 0.175781 0.175781 C 0.238281 0.261719 0.285156 0.332031 0.3125 0.394531 C 0.328125 0.382812 0.34375 0.371094 0.359375 0.359375 C 0.371094 0.34375 0.382812 0.328125 0.394531 0.3125 C 0.332031 0.285156 0.261719 0.238281 0.175781 0.175781 Z M 0.6875 0.605469 C 0.671875 0.617188 0.65625 0.628906 0.640625 0.640625 C 0.628906 0.65625 0.617188 0.671875 0.605469 0.6875 C 0.667969 0.714844 0.738281 0.761719 0.828125 0.828125 C 0.761719 0.738281 0.714844 0.667969 0.6875 0.605469 Z M 0.3125 0.605469 C 0.285156 0.667969 0.238281 0.738281 0.171875 0.828125 C 0.261719 0.761719 0.332031 0.714844 0.394531 0.6875 C 0.382812 0.671875 0.371094 0.65625 0.359375 0.640625 C 0.34375 0.628906 0.328125 0.617188 0.3125 0.605469 Z M 0.3125 0.605469 " />
              </clipPath>
            </defs>
          </svg>
          <video loop autoPlay muted poster="https://images.unsplash.com/photo-1487715433499-93acdc0bd7c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1844&q=80"
            src="http://thenewcode.com/assets/videos/mountain.mp4" type="video/mp4">
          </video>
        </figure>
        <figure className="starSvg" id="svg4">
          <svg height="0" width="0">
            <defs>
              <clipPath id="svg__path4" clipPathUnits="objectBoundingBox">
                <path id="svg__rec4" data-name="rec4" d="M0,0H1V1H0Z" />
                <path id="svg__star4" data-name="star4" d="M 0.5 0.0390625 C 0.472656 0.214844 0.449219 0.320312 0.382812 0.382812 C 0.320312 0.449219 0.214844 0.472656 0.0390625 0.5 C 0.214844 0.527344 0.320312 0.550781 0.382812 0.617188 C 0.449219 0.679688 0.472656 0.785156 0.5 0.960938 C 0.527344 0.785156 0.550781 0.679688 0.617188 0.617188 C 0.679688 0.550781 0.785156 0.527344 0.960938 0.5 C 0.785156 0.472656 0.679688 0.449219 0.617188 0.382812 C 0.550781 0.320312 0.527344 0.214844 0.5 0.0390625 Z M 0.828125 0.171875 C 0.738281 0.238281 0.667969 0.285156 0.605469 0.3125 C 0.617188 0.328125 0.628906 0.34375 0.640625 0.359375 C 0.65625 0.371094 0.671875 0.382812 0.6875 0.394531 C 0.714844 0.332031 0.761719 0.261719 0.828125 0.171875 Z M 0.175781 0.175781 C 0.238281 0.261719 0.285156 0.332031 0.3125 0.394531 C 0.328125 0.382812 0.34375 0.371094 0.359375 0.359375 C 0.371094 0.34375 0.382812 0.328125 0.394531 0.3125 C 0.332031 0.285156 0.261719 0.238281 0.175781 0.175781 Z M 0.6875 0.605469 C 0.671875 0.617188 0.65625 0.628906 0.640625 0.640625 C 0.628906 0.65625 0.617188 0.671875 0.605469 0.6875 C 0.667969 0.714844 0.738281 0.761719 0.828125 0.828125 C 0.761719 0.738281 0.714844 0.667969 0.6875 0.605469 Z M 0.3125 0.605469 C 0.285156 0.667969 0.238281 0.738281 0.171875 0.828125 C 0.261719 0.761719 0.332031 0.714844 0.394531 0.6875 C 0.382812 0.671875 0.371094 0.65625 0.359375 0.640625 C 0.34375 0.628906 0.328125 0.617188 0.3125 0.605469 Z M 0.3125 0.605469 " />
              </clipPath>
            </defs>
          </svg>
          <video loop autoPlay muted poster="https://images.unsplash.com/photo-1503264116251-35a269479413?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            src="http://thenewcode.com/assets/videos/ocean-small.mp4" type="video/mp4">
          </video>
        </figure>
      </div>
    </>
  );
}

export default ScrollTest;
